var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"mb-1 mt-1"},[_vm._v(" Daftar Selesai Cetak ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1",attrs:{"variant":"warning","disabled":_vm.btnDownloadDisabled},on:{"click":_vm.downloadExcel}},[_c('b-spinner',{directives:[{name:"show",rawName:"v-show",value:(_vm.btnDownloadDisabled),expression:"btnDownloadDisabled"}],attrs:{"small":"","type":"grow"}}),_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"DownloadCloudIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Download All in Excel")])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":_vm.items,"select-options":{
      enabled: true,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    },"pagination-options":{
      enabled: true,
      perPage: _vm.pageLength,
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'id')?_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.id))])]):(props.column.field === 'action')?_c('span',[_c('span',[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.download(props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Download Foto")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.downloadQR(props.row)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"Edit2Icon"}}),_c('span',[_vm._v("Download QR")])],1)],1)],1)]):(props.column.field === 'recent_order_status')?_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.recent_order_status))])]):(props.column.field === 'recent_order_created_at')?_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.recent_order_created_at))])]):_vm._e()]}}])}),_c('b-overlay',{attrs:{"show":_vm.processing,"no-wrap":""},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('b-spinner',{attrs:{"variant":"primary","label":"Text Centered"}})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }